const urls = JSON.parse(process.env.REACT_APP_URLS ?? '') ?? {
  solanaRpcApi: 'http://solana.premainnet.neontest.xyz:8899',
  neonProxyRpcApi: 'http://premainnet.neontest.xyz/solana'
};

export const environment = {
  governanceProgram: '4BXHQCVbeyEvDgaQ4XKaMuujCB9WKjx987jqVh4NVPCj', // GOVERNANCE_PROGRAM
  neonEvmProgram: '56XCgmB638ja7sPGN7ssCjm6JfCk95V95Rdvo4eR1ctB', // NEON_EVM_PROGRAM
  maintenanceProgram: '8Pw6wHeqrJdqgvZs2PGWH8YPPzEZZPXdLnfMCmkavrML', // MAINTENANCE_PROGRAM
  multisigProgram: 'rqswTzeHLCWjahuugFWeLRcH31vG51mn8tEGUYChZcR', // MULTISIG_PROGRAM
  memo: 'MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr', // MEMO_PROGRAM
  payer: 'r75qBmYsCxbyMe2kmFxdnsSFfxqDNxiJRUAURqyMkqr', // FEE_PAYER
  url: {
    explorer: 'https://explorer.solana.com',
    solanaRpcApi: urls.solanaRpcApi,
    neonProxyRpcApi: urls.neonProxyRpcApi
  }
};
// https://api.dao.neontest.xyz
// https://api.dao.neontest.xyz/node-solana
