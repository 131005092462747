import { Label } from './common';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

export type NetworkType = WalletAdapterNetwork | 'custom' | 'pre-mainnet';
export type SettingsNetwork = Label<NetworkType>;

export const enum SettingsStorage {
  settings = 'settings'
}

export interface SettingsFormState {
  programId: string;

  governanceProgramId?: string;
  neonEvmProgramId?: string;
  maintenanceProgramId?: string;
  multisigProgramId?: string;

  solanaRpcApi: string;
  wsSolanaRpcApi?: string;
  neonProxyRpcApi: string;
  network: NetworkType;
}

export interface SettingsFormProps {
  data?: SettingsFormState;

  onSubmit?(data: SettingsFormState): Promise<unknown> | void;
}
