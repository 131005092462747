import { useEffect, useState } from 'react';
import { MaintenanceProgram } from '../programs/maintenance/program';
import { ProgramBufferData } from '../programs/maintenance/schema';
import { AccountStorageKey, ProgramDataStorage } from '../maintenance/models';
import { useSettingsStorage } from './settings';
import { useLocalStorage } from './storage';

export default function useProgramBuffer(program: MaintenanceProgram, delay = 2000): [ProgramBufferData | null, boolean] {
  const [buffer, setBuffer] = useState<ProgramBufferData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [settings] = useSettingsStorage();
  const [programData, setProgramData] = useLocalStorage<ProgramDataStorage>(AccountStorageKey.programData, {} as ProgramDataStorage);
  const defDelay = delay;

  const maintenanceProgramBuffer = (): void => {
    setLoading(true);
    if (program && program.maintenanceProgramKey) {
      program.bufferAccountData(program.maintenanceProgramKey).then(buffer => {
        if (buffer) {
          setBuffer(buffer);
          buffer.toStorage().then(r => setProgramData(r));
        }
      }).finally(() => {
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    let timeout: any = null;
    const updateStatus = () => {
      if (program.maintenanceProgramKey) {
        console.log(program.maintenanceProgramKey.toBase58());
        program.bufferAccountSlot(program.maintenanceProgramKey, { offset: 4, length: 8 })
          .then(slot => {
            if (programData?.slot !== slot) {
              maintenanceProgramBuffer();
            } else if (programData.hasOwnProperty('data')) {
              ProgramBufferData.fromStorage(programData).then(b => setBuffer(b));
            }
            delay = defDelay;
            timeout = setTimeout(updateStatus, delay);
          })
          .catch(error => {
            console.error(error instanceof Error ? error?.message : `Can't get status update...`);
            delay = delay * 2;
            timeout = setTimeout(updateStatus, delay);
          });
      }
    };

    updateStatus();
    return () => timeout && clearTimeout(timeout);
  }, [settings, program.maintenanceProgramKey]);

  return [buffer, loading];
}
